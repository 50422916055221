import React, {useEffect, useRef} from 'react';
import {Switch, useLocation} from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import "./App.css"
// Layouts
import LayoutDefault from './layouts/LayoutDefault';
// Views
import Home from './views/Home';
import Contact from "./views/Contact";
import About from "./views/About";
// import ReactGA from 'react-ga';
import SimpleReactLightbox from 'simple-react-lightbox'
import OtherAlbumSection from "./components/elements/OtherAlbum";
import Other from "./views/Other";

// Initialize Google Analytics
// ReactGA.initialize(process.env.REACT_APP_GA_CODE);

// const trackPage = page => {
//   ReactGA.set({ page });
//   ReactGA.pageview(page);
// };

const App = () => {

    const childRef = useRef();
    let location = useLocation();

    useEffect(() => {
        const page = location.pathname;
        document.body.classList.add('is-loaded')
        childRef.current.init();
        // trackPage(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <SimpleReactLightbox>
            <ScrollReveal
                ref={childRef}
                children={() => (
                    <Switch>
                        <AppRoute exact path="/" component={Home} layout={LayoutDefault}/>
                        {/*<AppRoute exact path="/faq" component={Home} layout={LayoutDefault} />*/}
                        <AppRoute exact path="/contact" component={Contact} layout={LayoutDefault}/>
                        <AppRoute exact path="/about" component={About} layout={LayoutDefault}/>
                        <AppRoute exact path="/other" component={Other} layout={LayoutDefault}/>
                    </Switch>
                )}/>
        </SimpleReactLightbox>
    );
}

export default App;
