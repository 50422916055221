import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Cta from '../components/sections/Cta';
import AlbumSection from "../components/sections/AlbumSection";



const Home = () => {

    return (
        <>
            <Hero className="illustration-section-01" title={"Тотеми Видеосоник"}/>
            <AlbumSection/>
            <FeaturesTiles/>
            {/*<FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02"/>*/}
            {/*<Testimonial topDivider/>*/}
            <Cta split/>
        </>
    );
}

export default Home;
