import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Album from "../elements/Album";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import '../../bootstrap/bootstrap.min.css'
import {SRLWrapper} from "simple-react-lightbox";
import albumsJson from '../../totemi.json'

const useStyles = makeStyles((theme) => ({
    AlbumGridStyle: {
        marginTop: '30px',
    }
}));
const AlbumSection = () => {
    const classes = useStyles();

    const Albums = () => {
        let albums = [];
        for (let i = 0; i < albumsJson.length; i++) {
            albums.push(
                <Grid item md={4} sm={12} xs={12} className={classes.AlbumGridStyle} key={i}>
                    <Album
                        id={i}
                        image={`${process.env.PUBLIC_URL}${albumsJson[i].image}`}
                        title={albumsJson[i].title}
                        description={albumsJson[i].description}
                    />
                </Grid>
            )
        }
        return albums;
    }

    const Images = () => {
        let images = [];
        for (let i = 0; i < albumsJson.length; i++) {
            images.push(
                <img src={`${process.env.PUBLIC_URL}${albumsJson[i].image}`}
                     style={{display: 'none'}} key={i}/>
            )
        }
        return images;
    }

    return (
        <SRLWrapper>
            <Container>

                <Grid container>
                    {Albums()}
                </Grid>

                {Images()}
                {/* <Modal id={'modal'}
                   show={modalActive}
                   video={modalImage}
                   handleClose={closeModal}
                   className={''}
            />*/}
            </Container>
        </SRLWrapper>

    )
}
export default AlbumSection;
