import React from "react";
import '../bootstrap/bootstrap.min.css'

const About = () => {
    return (
        <div className={"container"} style={{marginBottom:'50px', marginTop:'5rem'}}>
            <section>
                <p>
                    Видеосоник котира на пазарот уште од 2006.
                </p>
                <p>
                    ......
                </p>
            </section>
        </div>
    )

}

export default About;
