import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import '../../App.css';
import {useLightbox} from "simple-react-lightbox";

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '3px',
        margin: '10px',
    },
    cardMedia: {
        // paddingTop: '56.25%', // 16:9
        paddingTop: '80%',
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center"

    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
}));

const Album = (props) => {
    const classes = useStyles();

    const {openLightbox} = useLightbox()
    console.log(props.image);
    return (

        <Card className={`${classes.card} grow`}>
            <CardMedia
                className={"zoom"}
                style={{paddingTop: '80%'}}
                image={props.image}
                title={props.title}
                onClick={() => openLightbox(props.id)}
            />
            {/*<img src={props.image}/>*/}
            <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h6" component="h3" color={"primary"}>
                    {props.title}
                </Typography>
                <Typography>
                    {props.description}
                    {/*<ReactMarkdown source={props.description} />*/}
                </Typography>
            </CardContent>
        </Card>
    )
}
export default Album;
