import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Cta from '../components/sections/Cta';
import OtherAlbumSection from "../components/elements/OtherAlbum";



const Other = () => {

    return (
        <>
            <Hero className="illustration-section-01" title={"Други услуги"}/>
            <OtherAlbumSection/>
            {/*<FeaturesTiles/>*/}
            {/*<FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02"/>*/}
            {/*<Testimonial topDivider/>*/}
            <Cta split/>
        </>
    );
}

export default Other;
