//import '../bootstrap/bootstrap.min.css';
import React, {useState} from "react";
import {MDBAlert} from "mdbreact";
import '../bootstrap/bootstrap.min.css';

const Contact = (props) => {

    const [isSent, setIsSent] = useState(false);

    const onFormSubmit = (e) => {
        e.preventDefault();
        let data = {
            sender: e.target.name.value,
            reply_to: e.target.email.value,
            subject: e.target.subject.value,
            content: e.target.content.value,
            date: new Date().toString()
        };
        // TODO: mail foward
        // window.location = "/contact";
    };

    let sentDiv;
    isSent === true ? sentDiv = <MDBAlert className={"mt-4"} color={"success"}> Email sent!</MDBAlert> : sentDiv = null;

    return (
        <div className={"container"} style={{marginBottom:'50px', marginTop:'5rem'}}>
            <section>

                <h2 className=" h1-responsive font-weight-bold text-center text-color-primary">
                    Контакт
                </h2>
                <div className="row">
                    <div className="col-lg-4 mb-lg-0 mb-4">

                        <form onSubmit={onFormSubmit}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-header accent-1">
                                        <h3 className="mt-2 text-color-primary">Контактирајте не</h3>
                                    </div>
                                    <div className="md-form">
                                        <input type="text" id="form-name" className="form-control mb-1" name="name"
                                               style={{borderStyle:'none none solid none'}}
                                               placeholder={"Your name"}
                                        />
                                    </div>
                                    <div className="md-form">
                                        <input type="text" id="form-email" className="form-control mb-1" name="email"
                                               style={{borderStyle:'none none solid none'}}
                                               required
                                               placeholder={"e-mail"}
                                        />
                                    </div>
                                    <div className="md-form">
                                        <input type="text" id="form-Subject" className="form-control mb-1" name="subject"
                                               style={{borderStyle:'none none solid none'}}
                                               placeholder={"Subject"}
                                        />
                                    </div>
                                    <div className="md-form">
                                            <textarea id="form-text" className="form-control md-textarea mb-1" rows="3"
                                                      style={{borderStyle:'none none solid none'}}
                                                      name="content" required
                                                      placeholder={"Content"}
                                            />
                                    </div>
                                    <br/>
                                    <div className="text-center mb-16">
                                        <button className="btn btn-success">Submit</button>
                                    </div>
                                </div>
                            </div>
                            {sentDiv}
                        </form>

                    </div>

                    <div className="col-lg-8">

                        <div id="map-container-section" className="z-depth-1-half map-container-section mb-4"
                             style={{height: "400px"}}>
                            <iframe
                                src="https://maps.google.com/maps?q=videosonik&t=&z=15&ie=UTF8&iwloc=&output=embed"
                                frameBorder="0"
                                style={{border: "0", width: "100%", height: "100%"}} allowFullScreen/>
                        </div>
                        <div className="row text-center text-black-50 font-weight-bold">
                            <div className="col-md-3">
                                <p className="mb-md-0">Macedonia, Skopje</p>
                            </div>
                            <div className="col-md-3">
                                <p>zocky@t.mk</p>
                            </div>
                            <div className="col-md-3">
                                <p>070-219-828</p>
                            </div>
                        </div>

                    </div>

                </div>

            </section>
        </div>

    )
};
export default Contact;



